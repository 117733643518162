import React from "react"
import { Helmet } from "react-helmet"
import WhatsAppWidget from "react-whatsapp-widget"
import "react-whatsapp-widget/dist/index.css"

import { useSiteMetadata } from "../hooks/use-site-metadata"
import { useIntl } from "gatsby-plugin-intl"

import Header from "./header"
import Footer from "./footer"

import "../styles/default.css"
import "../styles/layout.css"
import "../styles/media-queries.css"
import "../styles/animate.css"
import "../styles/prettyPhoto.css"

import "typeface-open-sans"
import "typeface-montserrat"

import { config } from "@fortawesome/fontawesome-svg-core"
import "@fortawesome/fontawesome-svg-core/styles.css"
config.autoAddCss = false

export default function Layout({ children }) {
  const { title, meta, favicon } = useSiteMetadata()
  const { description = "", author = "", keywords = "" } = meta || {}
  const intl = useIntl()

  return (
    <>
      <Helmet>
        <title>{title}</title>
        <meta name="description" content={description} />
        <meta name="author" content={author} />
        <meta name="keywords" content={keywords} />

        <meta
          name="viewport"
          content="width=device-width, initial-scale=1, maximum-scale=1"
        />

        <link rel="shortcut icon" href={"/" + favicon} type="image/png" />
      </Helmet>

      <Header />

      <main id="top">{children}</main>

      <Footer />

      <div className="wa-btn">
        <WhatsAppWidget
          phoneNumber="50688361428"
          message={intl.formatMessage({ id: "whatsapp.message" })}
          textReplyTime={intl.formatMessage({ id: "whatsapp.replyTime" })}
          sendButton={intl.formatMessage({ id: "whatsapp.send" })}
          placeholder={intl.formatMessage({ id: "whatsapp.placeholder" })}
          companyName="Vermeza Importaciones"
        />
      </div>
    </>
  )
}
