import React, { useState } from "react"
import Lightbox from "react-image-lightbox"
import "react-image-lightbox/style.css" // This only needs to be imported once in your app
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { faPlus } from "@fortawesome/free-solid-svg-icons"
import { categoryImages } from "../assets/assets"
import { useIntl } from "gatsby-plugin-intl"

const { furniture, construction, events, novelty } = categoryImages

const categories = [
  {
    name: "furniture",
    imgUrl: furniture,
  },
  {
    name: "construction",
    imgUrl: construction,
  },
  {
    name: "events",
    imgUrl: events,
  },
  {
    name: "novelty",
    imgUrl: novelty,
  },
]

const maxProdsPerCat = 5

export default () => {
  const [photoIndex, setPhotoIndex] = useState(0)
  const [isOpen, setIsOpen] = useState(false)
  const [currentCat, setCurrentCat] = useState("")

  const intl = useIntl()

  return (
    <section id="products">
      <div className="row section-head">
        <h1>
          {intl.formatMessage({
            id: "products.title",
          })}
        </h1>
        <p>
          {intl.formatMessage({
            id: "products.body",
          })}
        </p>
      </div>
      <div className="">
        <div className="twelve columns">
          <div
            id="products-wrapper"
            className="bgrid-quarters s-bgrid-thirds cf"
          >
            {categories.map((product, index) => {
              return (
                <div className="columns item" key={index.toString()}>
                  <div className="item-wrap">
                    <a
                      href="#"
                      data-imagelightbox="a"
                      onClick={event => {
                        event.preventDefault()
                        setIsOpen(true)
                        setCurrentCat(product.name)
                      }}
                    >
                      <img src={product.imgUrl} alt={product.name} />
                      <div className="overlay">
                        <p>
                          {intl.formatMessage({
                            id: `products.categories.${product.name}.name`,
                          })}
                        </p>
                      </div>
                      <div className="link-icon">
                        <FontAwesomeIcon icon={faPlus} />
                      </div>
                    </a>
                  </div>
                </div>
              )
            })}
          </div>
        </div>
      </div>
      <div>
        {isOpen && (
          <Lightbox
            mainSrc={`../images/products/${currentCat}/${photoIndex}.png`}
            nextSrc={`../images/products/${currentCat}/${
              (photoIndex + 1) % maxProdsPerCat
            }.png`}
            prevSrc={`../images/products/${currentCat}/${
              (photoIndex + maxProdsPerCat - 1) % maxProdsPerCat
            }.png`}
            onCloseRequest={() => {
              setIsOpen(false)
              setCurrentCat("")
              setPhotoIndex(0)
            }}
            onMovePrevRequest={() =>
              setPhotoIndex((photoIndex + maxProdsPerCat - 1) % maxProdsPerCat)
            }
            onMoveNextRequest={() =>
              setPhotoIndex((photoIndex + 1) % maxProdsPerCat)
            }
            //imageCaption={products[photoIndex].caption}
            enableZoom={false}
          />
        )}
      </div>
    </section>
  )
}
