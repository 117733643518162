import React from "react"
import Slider from "react-slick"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { faQuoteRight, faQuoteLeft } from "@fortawesome/free-solid-svg-icons"
import { projectImages } from "../assets/assets"
import { useIntl } from "gatsby-plugin-intl"

const { ucr, una, lyra, toyota, toldos } = projectImages

const quotes = [1, 2]

const projects = [
  {
    name: "ucr",
    img: ucr,
  },
  {
    name: "una",
    img: una,
  },
  {
    name: "lyra",
    img: lyra,
  },
  {
    name: "toyota",
    img: toyota,
  },
  {
    name: "toldos",
    img: toldos,
  },
]

const MySlider = () => {
  const settingsQuotes = {
    dots: true,
    infinite: true,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
    arrows: false,
    adaptiveHeight: false,
    autoplay: true,
    autoplaySpeed: 7000,
    draggable: false,
    appendDots: dots => <ol> {dots} </ol>,
    customPaging: i => <button>{i + 1}</button>,
  }

  const settingsProjects = {
    dots: false,
    infinite: true,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
    autoplay: false,
    draggable: true,
  }

  const intl = useIntl()

  return (
    <section id="testimonials">
      <div id="projects">
        <div className="row content">
          <div className="twelve columns flex-container">
            <Slider {...settingsProjects} style={{ userSelect: "text" }}>
              {projects.map((project, index) => (
                <div key={index}>
                  <h3>
                    {intl.formatMessage({
                      id: `projects.${project.name}.title`,
                    })}
                  </h3>
                  <p>
                    {intl.formatMessage({
                      id: `projects.${project.name}.description`,
                    })}
                  </p>
                  <img src={project.img} alt={project.name} />
                </div>
              ))}
            </Slider>
          </div>
        </div>
      </div>
      <div id="cites">
        <div className="row content">
          <span>
            <i className="quote-left fa">
              <FontAwesomeIcon icon={faQuoteLeft} />
            </i>
          </span>
          <div className="text-container">
            <div className="twelve columns">
              <h1>
                {intl.formatMessage({
                  id: "testimonials.title",
                })}
              </h1>
            </div>
            <div className="twelve columns flex-container">
              <Slider {...settingsQuotes} style={{ userSelect: "text" }}>
                {quotes.map((quote, index) => (
                  <div key={index}>
                    <blockquote>
                      <p>
                        {intl.formatMessage({
                          id: `testimonials.${index}.quote`,
                        })}
                      </p>
                      <cite>
                        {intl.formatMessage({
                          id: `testimonials.${index}.cite`,
                        })}
                      </cite>
                    </blockquote>
                  </div>
                ))}
              </Slider>
            </div>
          </div>
          <span>
            <i className="quote-right fa">
              <FontAwesomeIcon icon={faQuoteRight} />
            </i>
          </span>
        </div>
      </div>
    </section>
  )
}

export default MySlider
