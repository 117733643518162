const video = { heroVideo: require("./video/vermeza.mp4") }

const serviceImages = {
  importer: require("./images/services/importer.webp"),
  intermediary: require("./images/services/intermediary.jpg"),
}

const categoryImages = {
  furniture: require("./images/products/furniture/0.png"),
  construction: require("./images/products/construction/0.png"),
  events: require("./images/products/events/0.png"),
  novelty: require("./images/products/novelty/0.png"),
}

const projectImages = {
  ucr: require("./images/projects/ucr.jpg"),
  una: require("./images/projects/una.jpg"),
  lyra: require("./images/projects/lyra.webp"),
  toyota: require("./images/projects/toyota.webp"),
  toldos: require("./images/projects/toldos.jpg"),
}

export { serviceImages, video, categoryImages, projectImages }
