import React from "react"
import { useSiteMetadata } from "../hooks/use-site-metadata"
import scrollTo from "gatsby-plugin-smoothscroll"

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import {
  faChevronUp,
  faUsers,
  faEnvelope,
} from "@fortawesome/free-solid-svg-icons"

export default () => {
  const { social, contacts, logo } = useSiteMetadata()
  return (
    <footer>
      <div className="row">
        <div className="columns info">
          <div className="footer-logo">
            <img src={logo} alt="" />
          </div>

          <p>Vermeza Importaciones S.A.</p>
        </div>

        <div className="six columns right-cols">
          <div className="row">
            <div className="columns">
              <FontAwesomeIcon icon={faUsers} />
              <h3 className="social">socialize</h3>
              <ul>
                {social.map(mySocial => (
                  <li>
                    <a href={mySocial.url}>{mySocial.service}</a>
                  </li>
                ))}
              </ul>
            </div>

            <div className="columns contact-fix">
              <FontAwesomeIcon icon={faEnvelope} />
              <h3 className="contact">Contact Us</h3>
              <ul>
                {contacts.map((contact, index) => (
                  <li>
                    <a href={contact.url}>{contact.text}</a>
                  </li>
                ))}
              </ul>
            </div>
          </div>
        </div>

        <div id="go-top">
          <button title="Back to Top" onClick={() => scrollTo("#top")}>
            <FontAwesomeIcon icon={faChevronUp} />
          </button>
        </div>
      </div>
    </footer>
  )
}
