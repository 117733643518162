import React from "react"
import { useSiteMetadata } from "../hooks/use-site-metadata"
import Language from "./Language"

import NavMenu from "./navmenu"
import HeaderSocial from "./header-social"

export default () => (
  <header>
    <div className="logo">
        <img alt="" src={useSiteMetadata().logo} />
    </div>

    <NavMenu />
    <div className="language-container">
      <Language />
    </div>
    <HeaderSocial />
  </header>
)
