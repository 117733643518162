import React from "react"
import ScrollAnimation from "react-animate-on-scroll"
import { serviceImages } from "../assets/assets"
import { useIntl } from "gatsby-plugin-intl"

const { importer, intermediary } = serviceImages

const content = [
  {
    name: "intermediary",
    imgUrl: intermediary,
    bullets: [1, 2, 3, 4],
  },
  {
    name: "importer",
    imgUrl: importer,
    bullets: [1, 2, 3, 4],
  },
]

export default () => {
  const intl = useIntl()

  return (
    <section id="services">
      <p className="header">
        {intl.formatMessage({
          id: "services.header",
        })}
      </p>
      <hr />
      {content.map((service, index) => {
        const isIntermediary = service.name === "intermediary"
        const fadeInType = isIntermediary ? "fadeInRight" : "fadeInLeft"
        return (
          <div
            className={`services-container ${service.name}`}
            key={index.toString()}
          >
            <ScrollAnimation
              animateIn={fadeInType}
              animateOnce={true}
              initiallyVisible={false}
              delay={1}
              duration={1.5}
              className="feature-media"
            >
              <div
                className={`services-container-desc${
                  isIntermediary ? " right" : " bottom"
                }`}
              >
                <h3>
                  {intl.formatMessage({
                    id: `services.${service.name}Title`,
                  })}
                </h3>
                {service.bullets && service.bullets.length ? (
                  <ul>
                    {service.bullets.map((bullet, idx) => (
                      <li key={idx.toString()}>
                        {intl.formatMessage({
                          id: `services.${service.name}Bullets.${bullet}`,
                        })}
                      </li>
                    ))}
                  </ul>
                ) : null}
              </div>
            </ScrollAnimation>
          </div>
        )
      })}
    </section>
  )
}
